let networkName = 'mainnet';
const pro = `https://${networkName}.binpaytech.io`;
const dev = `http://localhost:3000`;
const url = pro;

const Config = {
    Root: {
        apiUrl: `${url}/api/`,
        socketServerUrl: url === pro ? `https://${networkName}socket.binpaytech.io` : 'http://localhost:4000',
        socket: null,
        transactionPage: {
            USDT:
                networkName === 'mainnet'
                    ? {
                          tx: 'https://tronscan.org/#/transaction',
                          addr: 'https://tronscan.org/#/address'
                      }
                    : {
                          tx: 'https://nile.tronscan.org/#/transaction',
                          addr: 'https://nile.tronscan.org/#/address'
                      },
            TRX:
                networkName === 'mainnet'
                    ? {
                          tx: 'https://tronscan.org/#/transaction',
                          addr: 'https://tronscan.org/#/address'
                      }
                    : {
                          tx: 'https://nile.tronscan.org/#/transaction',
                          addr: 'https://nile.tronscan.org/#/address'
                      },
            USDC:
                      networkName === 'mainnet'
                          ? {
                                tx: 'https://tronscan.org/#/transaction',
                                addr: 'https://tronscan.org/#/address'
                            }
                          : {
                                tx: 'https://nile.tronscan.org/#/transaction',
                                addr: 'https://nile.tronscan.org/#/address'
                            },
            ETH:
                networkName === 'mainnet'
                    ? {
                          tx: 'https://etherscan.io/tx',
                          addr: 'https://etherscan.io/address'
                      }
                    : {
                          tx: 'https://goerli.etherscan.io/tx',
                          addr: 'https://goerli.etherscan.io/address'
                      },
            BTC:
                networkName === 'mainnet'
                    ? {
                          tx: 'https://sochain.com/tx/BTC',
                          addr: 'https://sochain.com/address/BTC'
                      }
                    : {
                          tx: 'https://sochain.com/tx/BTCTEST',
                          addr: 'https://sochain.com/address/BTCTEST'
                      },
            DOGE:
                      networkName === 'mainnet'
                          ? {
                                tx: 'https://sochain.com/tx/DOGE',
                                addr: 'https://sochain.com/address/DOGE'
                            }
                          : {
                                tx: 'https://sochain.com/tx/DOGETEST',
                                addr: 'https://sochain.com/address/DOGETEST'
                            }
        }
    },
    token: `dash-${networkName}.binpaytech.io-crypto-admin-token`,
    request: {
        sessionCheck: '/auth/session',
        Login: '/auth/login',
        confirmAuthCode: '/auth/confirmAuthCode',
        submitChangePassword: '/auth/submitChangePassword',

        submitUser: '/customer/addUpdate',
        getCustomer: '/customer/getCustomer',
        verify2FA: '/customer/verify2FA',

        getWallets: '/wallets/getWallets',

        sendOutMoney: '/crypto/sendOutMoney',
        transferBalance: '/crypto/transferBalance',
        bulkTransfer: '/crypto/bulkTransfer',
        getContract: '/customer/getContract',
        addContract: '/customer/addContract',
        removeContract: '/customer/removeContract',
        sendOutMoneyCheck: '/crypto/sendOutMoneyCheck',
        sendTrx: '/crypto/sendTrx',
        sendTrxCheck: '/crypto/sendTrxCheck',
        mergeUsdt: '/crypto/mergeUsdt',
        bugFixMoney: '/crypto/bugFixMoney',
        transferTocold: '/crypto/outColdWallet',
        exportWallet: '/crypto/coinsToMainWallet',

        getSettingData: '/setting/getSettingData',
        generateNewWallet: '/setting/generateNewWallet',
        updateModeStatus: '/setting/updateModeStatus',
        updateminmaxAmount: '/setting/updateminmaxAmount',

        getWalletAddress: '/payment/getWalletAddress',
        expireAddress: '/payment/expireAddress',
        parseUrlData: '/payment/parseUrlData',
        parseUrlToken: '/payment/parseURLToken',
        requestWithdraw: '/payment/requestWithdraw',
        updateAddressTimeOut: '/payment/updateAddressTimeOut',
        cancelWithdrawFunction: '/payment/cancelWithdrawFunction',

        getAdminReport: '/report/getAdminReport',
        getCustomerReport: '/report/getCustomerReport',
        getAdminApprovalList: '/report/getAdminApprovalList',
        getWalletReport: '/report/getWalletReport',
        getAdminLogReport: '/report/getAdminLogReport',
        getCustomerList: '/report/getCustomerList',
        getCustomerIDs: '/report/getCustomerIDs',

        getAdminBalance: '/report/getAdminBalance',
        getPrice: '/report/getPrice',
        getChartsData: '/report/getChartsData',
        getPieData: '/report/getPieData',
        getOptionData: '/report/getOptionData',
        getTodayData: '/report/getTodayData',
        getTransactionCount: '/report/getTransactionCount'
    }
};

export default Config;
